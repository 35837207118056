import React from 'react';

import image from './assets/image.jpg';

import { Heading, Paragraph, Button, PhotoToVideoEmbed } from 'components';

import styles from './styles.module.scss';

const Header = ({ openBecomeASpeakerModal }) => (
  <header className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.left}>
        <PhotoToVideoEmbed
          image={image}
          className={styles.videoEmbed}
          video="https://vimeo.com/421015912"
          text="Watch how our speakers described Connect"
        />
      </div>
      <div className={styles.right}>
        {/* we need this to be able to center according to the widest element */}
        <div className={styles.content}>
          <Heading className={styles.title} size="medium">
            Share your thought leadership with our unique audience of
            forward-thinkers and help shape the future of the built environment
            - globally.
          </Heading>
          <Paragraph className={styles.description} color="grey" size="medium">
            Join our exclusive speaker club and educate the real estate industry
            - worldwide. Powered by Unissu.
          </Paragraph>
          <Paragraph className={styles.subInfo} size="small">
            filming in October 2020
          </Paragraph>
          <Button
            textColor="white"
            backgroundColor="blue"
            borderColor="blue"
            size="medium"
            onClick={openBecomeASpeakerModal}>
            Become a Speaker
          </Button>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
